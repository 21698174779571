import { FC, lazy, LazyExoticComponent, Suspense } from "react";
import { HashRouter as Router, Routes, Route } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import "react-toastify/dist/ReactToastify.min.css";
import { ProtectedRoute, PublicRoute } from "./components/auth/auth";
import PageNotFound from "./components/misc/pagenotfound";
import LazyLoading from "./components/misc/lazyLoading";

//Platform routes
const CustomForm = lazy(() => import("./pages/platform/custom-form"));
const CustomPage = lazy(() => import("./pages/platform/custom-page"));
const Login = lazy(() => import("./pages/platform/login"));
const Home = lazy(() => import("./pages/platform/home"));
const Users = lazy(() => import("./pages/platform/users"));
const Roles = lazy(() => import("./pages/platform/roles"));

const Voucher = lazy(() => import("./pages/invoice/voucher"));
const InvoiceViewPage = lazy(() => import("./pages/invoice/invoice-view"));
const ReportPage = lazy(() => import("./pages/reports/reports"));
const AddItemMobileView = lazy(() => import("./pages/mobile-view/add-item"));
const CreateCustomer = lazy(() => import("./pages/mobile-view/create-customer"));
const DeliveryPage = lazy(() => import("./pages/mobile-view/delivery-page"));
const CollectionScheduleViewPage = lazy(() => import("./pages/mobile-view/collection-schedule-view-page"));
const CustomerViewPage = lazy(() => import("./pages/mobile-view/customer-view-page"));
const SupplierViewPage = lazy(() => import("./pages/mobile-view/supplier-view-page"));
const ReportAccessPage = lazy(() => import("./pages/reports/report-access"));
const B2BVoucherPage = lazy(() => import("./pages/invoice/B2B-voucher"));
const B2BAddItemMobileView = lazy(() => import("./pages/mobile-view/b2b-add-item"));
const B2BSalesViewPage = lazy(() => import("./pages/mobile-view/b2b-sales-view-page"));
const Dashboard = lazy(() => import("./pages/dashboard/dashboard"));
const Lending = lazy(() => import("./pages/invoice/lending"));
const CustomerViewPageNoWorkflow = lazy(() => import("./pages/mobile-view/customer-view-page-no-workflow"));
const VoucherWithoutWorkflow = lazy(() => import("./pages/invoice/voucher-no-workflow"));
const CollectionSchedule = lazy(() => import("./pages/collection-schedule/collection-schedule"));
const CustomerDetails = lazy(() => import("./pages/customers/customer-details"));
const EmiCollection = lazy(() => import("./pages/mobile-view/list-collection-schedule"));
const ListSalesInvoice = lazy(() => import("./pages/invoice/list-sales-invoice"));
const B2BListSalesInvoice = lazy(() => import("./pages/invoice/B2B-list-sales-invoice"));
const FranchiseDetails = lazy(() => import("./pages/customers/franchise-details"));
const DeliveredInvoicePage = lazy(() => import("./pages/mobile-view/delivered-invoice-page"));
const ListPurchaseInvoice = lazy(() => import("./pages/invoice/list-purchase-invoice"));
const ConfirmedInvoicePage = lazy(() => import("./pages/mobile-view/confirmed-invoice-page"));
const DebitNoteInvoicePage = lazy(() => import("./pages/debit-note/debit-note-invoice"));
const CreditNoteInvoicePage = lazy(() => import("./pages/credit-note/credit-note-invoice"));
const NewB2BCustomerDetails = lazy(() => import("./pages/customers/new-b2b-customer-details"));
const ProformaInvoice = lazy(() => import("./pages/proforma-invoice/proforma-invoice"));
const ProformaInvoiceView = lazy(() => import("./pages/proforma-invoice/proforma-invoice-view"));
const ListProformaInvoice = lazy(() => import("./pages/proforma-invoice/list-proforma-invoice"));
const PurchaseOrderInvoice = lazy(() => import("./pages/purchase-order/purchase-order-invoice"));
const PurchaseOrderView = lazy(() => import("./pages/purchase-order/purchase-order-view"));
const ListPurchaseOrder = lazy(() => import("./pages/purchase-order/list-purchase-order"));
const NewB2BCustomerView = lazy(() => import("./pages/mobile-view/new-b2b-customer-view-page"));
const NewB2BDeliveryPage = lazy(() => import("./pages/mobile-view/new-b2b-delivery-page"));
const ReceivablesViewPage = lazy(() => import("./pages/mobile-view/receivables-view-page"));
const Receivables = lazy(() => import("./pages/collection-schedule/receivables"));
const ListReceivables = lazy(() => import("./pages/mobile-view/list-receivables"));
const ItemsReport = lazy(() => import("./pages/items/items-report"));
const SupplierDetails = lazy(() => import("./pages/suppliers/supplier-details"));
const ListDeliveredInvoice = lazy(() => import("./pages/mobile-view/list-delivered-invoice"));
const Receipt = lazy(() => import("./pages/ledger/receipt"));
const CreateNewB2BCustomer = lazy(() => import("./pages/mobile-view/create-new-b2b-customer"));

function getComponentWithSuspense(
  Comp: LazyExoticComponent<FC<any>>,
  props: any
): JSX.Element {
  return (
    <Suspense fallback={<LazyLoading />}>
      <Comp {...props} />
    </Suspense>
  );
}

function App() {
  const isMobile = useMediaQuery("(max-width:600px)");
  const isTab = useMediaQuery("(min-width:600px)");
  const isLaptop = useMediaQuery("(min-width:768px)");
  const isDesktop = useMediaQuery("(min-width:992px)");
  const isLarge = useMediaQuery("(min-width:1200px)");
  const props = { isMobile, isTab, isLaptop, isDesktop, isLarge };

  return (
    <Router>
      <Routes>
        <Route path="/" element={<ProtectedRoute />}>
          <Route path="" element={getComponentWithSuspense(Home, props)}>
            <Route
              path="/"
              element={getComponentWithSuspense(Dashboard, props)}
            />
            <Route
              path="/page/:menu/:menu_profile_id"
              element={getComponentWithSuspense(CustomPage, props)}
            />
            <Route
              path="/form/:menu_profile_id"
              element={getComponentWithSuspense(CustomForm, props)}
            />
            <Route
              path="/users"
              element={getComponentWithSuspense(Users, props)}
            />
            <Route
              path="/roles"
              element={getComponentWithSuspense(Roles, props)}
            />
            <Route
              path="/voucher"
              element={getComponentWithSuspense(Voucher, props)}
            />
            <Route
              path="/b2b-voucher"
              element={getComponentWithSuspense(B2BVoucherPage, props)}
            />
            <Route
              path="/invoice_view"
              element={getComponentWithSuspense(InvoiceViewPage, props)}
            />
            <Route
              path="/report-view/:id"
              element={getComponentWithSuspense(ReportPage, props)}
            />
            <Route
              path="/add-item"
              element={getComponentWithSuspense(AddItemMobileView, props)}
            />
            <Route
              path="/b2b-add-item"
              element={getComponentWithSuspense(B2BAddItemMobileView, props)}
            />
            <Route
              path="/create-customer"
              element={getComponentWithSuspense(CreateCustomer, props)}
            />
            <Route
              path="/delivery-page/:id"
              element={getComponentWithSuspense(DeliveryPage, props)}
            />
            <Route
              path="/b2b-sales-view-page/:id"
              element={getComponentWithSuspense(B2BSalesViewPage, props)}
            />
            <Route
              path="/collection-schedule-page/:id"
              element={getComponentWithSuspense(CollectionScheduleViewPage, props)}
            />
            <Route
              path="/customer-view-page/:id"
              element={getComponentWithSuspense(CustomerViewPage, props)}
            />
            <Route
              path="/customer-view-page-no-workflow/:id"
              element={getComponentWithSuspense(CustomerViewPageNoWorkflow, props)}
            />
            <Route
              path="/supplier-view-page/:id"
              element={getComponentWithSuspense(SupplierViewPage, props)}
            />
            <Route
              path="/report-access"
              element={getComponentWithSuspense(ReportAccessPage, props)}
            />
            <Route
              path="/lending"
              element={getComponentWithSuspense(Lending, props)}
            />
            <Route
              path="/voucher-view"
              element={getComponentWithSuspense(VoucherWithoutWorkflow, props)}
            />
            <Route
              path="/collection-schedule"
              element={getComponentWithSuspense(CollectionSchedule, props)}
            />
            <Route
              path="/customer-details/:id"
              element={getComponentWithSuspense(CustomerDetails, props)}
            />
            <Route
              path="/emi-collection"
              element={getComponentWithSuspense(EmiCollection, props)}
            />
            <Route
              path="/list-sales-invoice"
              element={getComponentWithSuspense(ListSalesInvoice, props)}
            />
            <Route
              path="/list-purchase-invoice"
              element={getComponentWithSuspense(ListPurchaseInvoice, props)}
            />
            <Route
              path="/b2b-list-sales-invoice"
              element={getComponentWithSuspense(B2BListSalesInvoice, props)}
            />
            <Route
              path="/franchise-details/:id"
              element={getComponentWithSuspense(FranchiseDetails, props)}
            />
            <Route
              path="/delivered-invoice-page/:id"
              element={getComponentWithSuspense(DeliveredInvoicePage, props)}
            />
            <Route
              path="/confirmed-invoice-page/:id"
              element={getComponentWithSuspense(ConfirmedInvoicePage, props)}
            />
            <Route
              path="/debit-note-invoice"
              element={getComponentWithSuspense(DebitNoteInvoicePage, props)}
            />
            <Route
              path="/credit-note-invoice"
              element={getComponentWithSuspense(CreditNoteInvoicePage, props)}
            />
            <Route
              path="/b2b-customer-details/:id"
              element={getComponentWithSuspense(NewB2BCustomerDetails, props)}
            />
            <Route
              path="/proforma-invoice"
              element={getComponentWithSuspense(ProformaInvoice, props)}
            />
            <Route
              path="/proforma-invoice-view"
              element={getComponentWithSuspense(ProformaInvoiceView, props)}
            />
            <Route
              path="/list-proforma-invoice"
              element={getComponentWithSuspense(ListProformaInvoice, props)}
            />
            <Route
              path="/purchase-order"
              element={getComponentWithSuspense(PurchaseOrderInvoice, props)}
            />
            <Route
              path="/purchase-order-view"
              element={getComponentWithSuspense(PurchaseOrderView, props)}
            />
            <Route
              path="/list-purchase-order"
              element={getComponentWithSuspense(ListPurchaseOrder, props)}
            />
            <Route
              path="/new-b2b-customer-view-page/:id"
              element={getComponentWithSuspense(NewB2BCustomerView, props)}
            />
            <Route
              path="/b2b-delivery-page/:id"
              element={getComponentWithSuspense(NewB2BDeliveryPage, props)}
            />
            <Route
              path="/receivables-page/:id"
              element={getComponentWithSuspense(ReceivablesViewPage, props)}
            />
            <Route
              path="/receivables"
              element={getComponentWithSuspense(Receivables, props)}
            />
            <Route
              path="/list-receivables"
              element={getComponentWithSuspense(ListReceivables, props)}
            />
            <Route
              path="items-report/:id"
              element={getComponentWithSuspense(ItemsReport, props)}
            />
            <Route
              path="supplier-details/:id"
              element={getComponentWithSuspense(SupplierDetails, props)}
            />
            <Route
              path="/list-delivered-invoice"
              element={getComponentWithSuspense(ListDeliveredInvoice, props)}
            />
            <Route
              path="/receipt"
              element={getComponentWithSuspense(Receipt, props)}
            />
            <Route
              path="/create-customer-b2b"
              element={getComponentWithSuspense(CreateNewB2BCustomer, props)}
            />
          </Route>
        </Route>
        <Route path="/login" element={<PublicRoute />}>
          <Route path="" element={getComponentWithSuspense(Login, props)} />
        </Route>
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </Router>
  );
}

export default App;
